




































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { TARGETING_KEYWORD_REPORTS } from 'src/utils/apollo-queries';
import * as moment from 'moment';
import Vue from 'vue';
import { CHANGE_KEYWORD_BID_AMOUNT } from 'src/utils/apollo-mutations';

export default Vue.extend({
  name: 'KeywordTable',
  data() {
    const today = moment().format('YYYY-MM-DD');
    const day2MonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
    return {
      filter: {
        dateRange: [day2MonthsAgo, today],
      },
      search: '',
      keywordsReport: null,
      keywordText: null,
      bidAmountDialog: false,
      selectionRadio: null,
      optimizeAllDialog: false,
      optimizeAllRadio: null,
      saving: false,
      detailsForUpdate: {
        orgId: null,
        campaignId: null,
        adGroupId: null,
        keywordId: null,
        minBidAmount: null,
        maxBidAmount: null,
        selectedBidAmount: null,
      },
    };
  },
  computed: {
    keyheaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('keyOpt.campaign'),
            align: 'start',
            value: 'campaignMetadata.name',
          },
          {
            text: this.$t('keyOpt.adgroup'),
            value: 'metadata.adGroupName',
          },
          {
            text: this.$t('keyOpt.key'),
            value: 'keywordMetadata.text',
          },
          {
            text: this.$t('keyOpt.bidAmt'),
            value: 'keywordMetadata.bidAmount.amount',
          },
          {
            text: this.$t('keyOpt.asaMinBid'),
            value: 'insights.bidRecommendation.bidMin.amount',
          },
          {
            text: this.$t('keyOpt.asaMaxBid'),
            value: 'insights.bidRecommendation.bidMax.amount',
          },
          {
            text: this.$t('keyOpt.action'),
            value: 'Accept',
            sortable: false,
          },
        ];
      },
    },
  },
  apollo: {
    targetingKeywordReports: {
      query: TARGETING_KEYWORD_REPORTS,
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.getKeywordsReport(data.targetingKeywordReports);
      },
    },
  },

  methods: {
    getKeywordsReport(keywords) {
      let tempKeywordReports = [];
      for (const key of keywords.row) {
        const minBid = key.insights.bidRecommendation.bidMin?.amount;
        const maxBid = key.insights.bidRecommendation.bidMax?.amount;
        if (!key.campaignMetadata.deleted) {
          if (
            key.keywordMetadata.bidAmount.amount <= minBid ||
            key.keywordMetadata.bidAmount.amount >= maxBid
          ) {
            tempKeywordReports.push(key);
          }
        }
      }
      let reportsSchema = {
        grandTotals: keywords.grandTotals,
        row: tempKeywordReports,
      };
      this.keywordsReport = reportsSchema;
    },

    bidAmount(item) {
      this.selectionRadio = 'Min';
      this.keywordText = item.keywordMetadata.text;
      this.bidAmountDialog = true;
      this.detailsForUpdate = {
        orgId: item.adgroupMetadata.orgId,
        campaignId: item.adgroupMetadata.campaignId,
        adGroupId: item.keywordMetadata.adGroupId,
        keywordId: item.keywordMetadata.id,
        minBidAmount: item.insights.bidRecommendation.bidMin,
        maxBidAmount: item.insights.bidRecommendation.bidMax,
      };
    },

    async changeKeywordBidAmount() {
      this.saving = true;
      if (this.selectionRadio == 'Min') {
        this.detailsForUpdate.selectedBidAmount = this.detailsForUpdate.minBidAmount;
      } else if (this.selectionRadio == 'Max') {
        this.detailsForUpdate.selectedBidAmount = this.detailsForUpdate.maxBidAmount;
      }
      const changeKeywordBidAmountReq = {
        orgId: this.detailsForUpdate.orgId,
        campaignId: this.detailsForUpdate.campaignId,
        adGroupId: this.detailsForUpdate.adGroupId,
        keywordBidAmount: {
          id: this.detailsForUpdate.keywordId,
          bidAmount: this.detailsForUpdate.selectedBidAmount,
        },
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: CHANGE_KEYWORD_BID_AMOUNT,
          variables: changeKeywordBidAmountReq,
        });

        if (result.data.changeKeywordBidAmount.searchAdsErrors) {
          this.$notify.error(
            result.data.changeKeywordBidAmount.searchAdsErrors
          );
        } else {
          this.$notify.success('Bid amount changed successfully.');
          this.bidAmountDialog = false;
        }
      } catch {}

      this.saving = false;
    },

    async updateAllBidAmount() {
      this.saving = true;
      let result;
      for (const key of this.keywordsReport.row) {
        let selectedBidAmount;
        if (this.optimizeAllRadio == 'Min') {
          selectedBidAmount = key.insights.bidRecommendation.bidMin;
        } else if (this.optimizeAllRadio == 'Max') {
          selectedBidAmount = key.insights.bidRecommendation.bidMax;
        }
        const changeKeywordBidAmountReq = {
          orgId: key.adgroupMetadata.orgId,
          campaignId: key.metadata.campaignId,
          adGroupId: key.keywordMetadata.adGroupId,
          keywordBidAmount: {
            id: key.keywordMetadata.id,
            bidAmount: selectedBidAmount,
          },
        };

        try {
          result = await this.$apollo.mutate({
            mutation: CHANGE_KEYWORD_BID_AMOUNT,
            variables: changeKeywordBidAmountReq,
          });
        } catch {}
      }
      if (result.data.changeKeywordBidAmount.searchAdsErrors) {
        this.$notify.error(result.data.changeKeywordBidAmount.searchAdsErrors);
      } else if (result.data.changeKeywordBidAmount.successMessage) {
        this.$notify.success('Bid amount changed successfully.');
        this.bidAmountDialog = false;
      } else {
        this.$notify.error('Something went wrong.');
      }
      this.saving = false;
      this.optimizeAllDialog = false;
    },
  },
});
