var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c("div", { staticClass: "top-page-header" }, [
              _c("div", { staticClass: "head-title" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("pages.keyOpt")))])
              ])
            ]),
            _c(
              "v-container",
              { staticClass: "v-main-content", attrs: { fluid: "" } },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 rounded-lg",
                      attrs: { elevation: "3" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "app" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pa-0" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("keyOpt.keyBid")) + " "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "max-width": "300px" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    depressed: "",
                                                    "min-width": "14",
                                                    "max-width": "14",
                                                    text: ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "secondary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("keyOpt.keyToolTip")))
                                  ])
                                ]
                              ),
                              _c("v-spacer"),
                              _c(
                                "div",
                                { staticClass: "v-search-with-btn" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: "Search",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary ml-3",
                                        depressed: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.optimizeAllDialog = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("keyOpt.optAll")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation-0 pa-0 mt-4",
                            attrs: {
                              loading:
                                _vm.$apollo.queries.targetingKeywordReports
                                  .loading,
                              "items-per-page": 5,
                              headers: _vm.keyheaders,
                              items:
                                (_vm.keywordsReport &&
                                  _vm.keywordsReport.row) ||
                                [],
                              search: _vm.search
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(Object.keys(_vm.$scopedSlots), function(
                                  slot
                                ) {
                                  return {
                                    key: slot,
                                    fn: function(scope) {
                                      return [_vm._t(slot, null, null, scope)]
                                    }
                                  }
                                }),
                                {
                                  key: "item.Accept",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "no-m-no-p",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    depressed: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.bidAmount(item)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("keyOpt.opt")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              ],
              2
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "600px" },
                model: {
                  value: _vm.bidAmountDialog,
                  callback: function($$v) {
                    _vm.bidAmountDialog = $$v
                  },
                  expression: "bidAmountDialog"
                }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "pa-5", attrs: { outlined: "" } },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "d-flex justify-space-between pa-0 mb-0" },
                      [
                        _c("h5", [_vm._v(_vm._s(_vm.$t("keyOpt.optBidAmt")))]),
                        _c(
                          "v-icon",
                          {
                            attrs: { disabled: _vm.saving },
                            on: {
                              click: function($event) {
                                _vm.bidAmountDialog = false
                              }
                            }
                          },
                          [_vm._v("mdi-close")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "rounded-0 border-x-0",
                              attrs: { outlined: "", height: "150" }
                            },
                            [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(_vm.$t("keyOpt.key")) +
                                    ": " +
                                    _vm._s(_vm.keywordText)
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.selectionRadio,
                                        callback: function($$v) {
                                          _vm.selectionRadio = $$v
                                        },
                                        expression: "selectionRadio"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Min Bid",
                                          value: "Min"
                                        }
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Max Bid",
                                          value: "Max"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.selectionRadio == "Min"
                                    ? _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          "prepend-inner-icon":
                                            "mdi-currency-usd"
                                        },
                                        model: {
                                          value:
                                            _vm.detailsForUpdate.minBidAmount
                                              .amount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.detailsForUpdate.minBidAmount,
                                              "amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailsForUpdate.minBidAmount.amount"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.selectionRadio == "Max"
                                    ? _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          "prepend-inner-icon":
                                            "mdi-currency-usd"
                                        },
                                        model: {
                                          value:
                                            _vm.detailsForUpdate.maxBidAmount
                                              .amount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.detailsForUpdate.maxBidAmount,
                                              "amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailsForUpdate.maxBidAmount.amount"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-md success--text mr-2",
                                  attrs: {
                                    width: "30",
                                    height: "30",
                                    depressed: "",
                                    outlined: "",
                                    disabled: _vm.saving,
                                    loading: _vm.saving
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeKeywordBidAmount()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-check")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-md error--text",
                                  attrs: {
                                    width: "30",
                                    height: "30",
                                    depressed: "",
                                    outlined: "",
                                    disabled: _vm.saving
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.bidAmountDialog = false
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "600px" },
                model: {
                  value: _vm.optimizeAllDialog,
                  callback: function($$v) {
                    _vm.optimizeAllDialog = $$v
                  },
                  expression: "optimizeAllDialog"
                }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "pa-5", attrs: { outlined: "" } },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "d-flex justify-space-between pa-0 mb-0" },
                      [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.$t("keyOpt.optBidForAll")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            attrs: { disabled: _vm.saving },
                            on: {
                              click: function($event) {
                                _vm.optimizeAllDialog = false
                              }
                            }
                          },
                          [_vm._v("mdi-close")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "rounded-0 border-x-0",
                              attrs: { outlined: "", height: "150" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.optimizeAllRadio,
                                        callback: function($$v) {
                                          _vm.optimizeAllRadio = $$v
                                        },
                                        expression: "optimizeAllRadio"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Min Bid",
                                          value: "Min"
                                        }
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Max Bid",
                                          value: "Max"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-md success--text mr-2",
                                  attrs: {
                                    width: "30",
                                    height: "30",
                                    depressed: "",
                                    outlined: "",
                                    disabled: _vm.saving,
                                    loading: _vm.saving
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateAllBidAmount()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-check")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-md error--text",
                                  attrs: {
                                    width: "30",
                                    height: "30",
                                    depressed: "",
                                    outlined: "",
                                    disabled: _vm.saving
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.optimizeAllDialog = false
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }